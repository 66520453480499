import { Link, NavLink, useLocation } from "react-router-dom";
import { config, menuItems } from "../util";
import { Nav, Navbar } from "react-bootstrap";
import { useEffect, useState } from "react";

function Header() {
  const location = useLocation();
  const [path, setPath] = useState("/");
  useEffect(() => {
    const pathName = location.pathname;
    setPath(pathName);
    window.scrollTo(0, 0);
  }, [location]);
  return (
    <>
      <div
        className="container-fluid bg-light p-0 wow fadeIn"
        data-wow-delay="0.1s"
      >
        <div className="row gx-0 d-none d-lg-flex">
          <div className="col-lg-7 px-5 text-start">
            <div className="h-100 d-inline-flex align-items-center py-3 me-4">
              <small className="fa fa-map-marker-alt text-primary me-2"></small>
              <small>{config.address}</small>
            </div>
            <div className="h-100 d-inline-flex align-items-center py-3">
              <small className="far fa-clock text-primary me-2"></small>
              <small>{config.timing}</small>
            </div>
          </div>
          <div className="col-lg-5 px-5 text-end">
            <div className="h-100 d-inline-flex align-items-center py-3 me-4">
              <small className="fa fa-phone-alt text-primary me-2"></small>
              <small>{config.displayPhone}</small>
            </div>
            <div className="h-100 d-inline-flex align-items-center">
              <a
                className="btn btn-sm-square rounded-circle bg-white text-primary me-1"
                href="2"
              >
                <i className="fab fa-facebook-f"></i>
              </a>
              <a
                className="btn btn-sm-square rounded-circle bg-white text-primary me-1"
                href="3"
              >
                <i className="fab fa-twitter"></i>
              </a>
              <a
                className="btn btn-sm-square rounded-circle bg-white text-primary me-1"
                href="4"
              >
                <i className="fab fa-linkedin-in"></i>
              </a>
              <a
                className="btn btn-sm-square rounded-circle bg-white text-primary me-0"
                href="5"
              >
                <i className="fab fa-instagram"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
      <Navbar
        collapseOnSelect
        className="navbar navbar-expand-lg bg-white navbar-light sticky-top p-0 wow fadeIn"
        bg="light"
        expand="lg"
      >
        <Navbar.Brand>
          <Link to={"/"} className="navbar-brand d-flex align-items-center">
            <img src="./../img/logo.png" style={{ height: 60 }} />
            <p
              style={{
                color: "#871f3e",
                textTransform: "uppercase",
                fontSize: 35,
                fontWeight: "bold",
                marginTop: 20,
                marginLeft: -40,
              }}
            >
              {config.appName}
            </p>
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" className="m-2" />

        <Navbar.Collapse className="collapse navbar-collapse">
          <Nav className="navbar-nav ms-auto p-4 p-lg-0">
            {menuItems.map((x, index) => {
              return (
                <Nav.Link
                  eventKey={index}
                  className={`nav-item nav-link ${
                    path === x.url ? "active" : ""
                  }`}
                  as={NavLink}
                  to={x.url}
                  key={index}
                >
                  {x.name}
                </Nav.Link>
              );
            })}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </>
  );
}

export default Header;
