import { chooseUsData, config } from "../util";
const AboutData = () => {
  return (
    <div className="p-5">
      <div className="row g-5">
        <div className="col-lg-4 wow fadeIn" data-wow-delay="0.1s">
          <div className="d-flex flex-column">
            <img
              className="img-fluid rounded w-75 align-self-end"
              src="img/about-1.jpg"
              alt=""
            />
            <img
              className="img-fluid rounded w-50 bg-white pt-3 pe-3"
              src="img/about-2.jpg"
              alt=""
              style={{ marginTop: "-25%" }}
            />
          </div>
        </div>
        <div className="col-lg-7 wow fadeIn" data-wow-delay="0.5s">
          <h1 className="mb-2">Welcome to {config.appName}</h1>
          <p>
            At {config.appName},is a one stop solution for all your building
            related services. Founded by group of experienced engineers to
            provide customer best quality and safe execution.
          </p>
          <p>
            we bring your visions to life. As a leading construction and
            planning firm, we specialize in creating spaces that inspire,
            innovate, and endure. With a commitment to excellence and a passion
            for precision, we take pride in being your trusted partner in
            construction and development.
          </p>
          <h1 className="mt-5"> Why Choose Us?</h1>
          {chooseUsData.map((x, index) => {
            return (
              <p key={index}>
                <i className="far fa-check-circle text-primary me-3"></i>
                <strong>{x.title}</strong>
                {` - ${x.description}`}
              </p>
            );
          })}
          <h1 className="mt-5">Let's Build Something Extraordinary</h1>
          <p>
            Whether you're envisioning a new home, a commercial space, or an
            entire development, {config.appName} is ready to turn your dreams
            into reality. Explore our portfolio, learn about our team, and let's
            start the journey of building something extraordinary together.
            Contact us today to discuss your project and discover how we can
            make your vision a reality.
          </p>
        </div>
      </div>
    </div>
  );
};

export default AboutData;
