import PageHeader from "../components/PageHeader";
import ServiceData from "../components/ServiceData";
const Services = () => {
  return (
    <>
      <PageHeader pageName={"Services"} />
      <ServiceData />
    </>
  );
};

export default Services;
