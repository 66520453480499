import { useState } from "react";
import Carousel from "react-bootstrap/Carousel";
import { config, homeCarouselData } from "../util";
import AboutData from "../components/AboutData";
import PageHeader from "../components/PageHeader";
const HomePage = () => {
  const [index, setIndex] = useState(0);
  const handleSelect = (selectedIndex: any, e: any) => {
    setIndex(selectedIndex);
  };
  return (
    <>
      {/*  <Carousel activeIndex={index} onSelect={handleSelect}>
        {homeCarouselData.map((slide, i) => {
          return (
            <Carousel.Item interval={3000} key={i}>
              <img
                height={"500px"}
                className="d-block w-100"
                src={slide.image}
                alt="slider sf"
              />
              <Carousel.Caption>
                <h3>{slide.caption}</h3>
                <p>{slide.description}</p>
              </Carousel.Caption>
            </Carousel.Item>
          );
        })}
      </Carousel> */}
      <PageHeader pageName={`Welcome to ${config.appName} Planners`} />
      <AboutData />
    </>
  );
};

export default HomePage;
