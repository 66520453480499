import { servicesData } from "../util";

const ServiceData = () => {
  return (
    <div className="container-xxl py-5">
      <div className="container">
        <div
          className="text-center mx-auto mb-5 wow fadeInUp"
          data-wow-delay="0.1s"
          style={{ maxWidth: "600px" }}
        >
          <h1>Our Expertise</h1>
        </div>
        <div className="row g-4">
          {servicesData.map((x, index) => {
            return (
              <div
                key={index}
                className="col-lg-4 col-md-6 wow fadeInUp"
                data-wow-delay="0.1s"
              >
                <div className="service-item bg-light rounded h-100 p-3">
                  <div
                    className="d-inline-flex align-items-center justify-content-center bg-white rounded-circle mb-3"
                    style={{ width: "35px", height: "35px" }}
                  >
                    <i className={`fa fa-${x.icon} text-primary fs-4`}></i>
                  </div>
                  <div className="d-inline-flex ms-2">
                    <h4>{x.name}</h4>
                  </div>
                  <p className="mb-5">{x.data}</p>
                  {/*  <a className="btn" href="3">
                <i className="fa fa-plus text-primary me-3"></i>Read More
              </a> */}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default ServiceData;
