import AboutData from "../components/AboutData";
import PageHeader from "../components/PageHeader";
const AboutPage = () => {
  return (
    <>
      <PageHeader pageName={"About Us"} />
      <AboutData />
    </>
  );
};

export default AboutPage;
