import { Tabs, Tab, Row, Card, Col } from "react-bootstrap";
import PageHeader from "../components/PageHeader";
import { projectData, servicesData } from "../util";
import { useEffect, useState } from "react";
const ProjectsPage = () => {
  const [data, setData] = useState<any>(
    projectData.filter((x) => x.id === 1)[0]
  );
  const [img, setImg] = useState<any>([]);
  const handleTab = (id: any) => {
    setData(projectData.filter((x) => x.id === parseInt(id))[0]);
  };
  useEffect(() => {
    if (data) {
      let folderName = data.name.toLowerCase();
      const dummyArray = [];
      for (let i = 1; i <= new Array(data.img).length; i++) {
        dummyArray.push(`img/projects/${folderName}/${i}.jpg`);
      }
      setImg(dummyArray);
    }
  }, [data]);
  const ImageData = () => {
    return (
      <div className="row g-3">
        {img.map((x: any, index: number) => {
          return (
            <div
              key={index}
              className="col-lg-3 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <Card style={{ width: "18rem" }}>
                <Card.Img
                  variant="top"
                  src={x}
                  height={"300px"}
                  width={"300px"}
                />
              </Card>
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <>
      <PageHeader pageName={"Our Works"} />
      <div className="container">
        <Tabs
          defaultActiveKey="1"
          id="fill-tab-example"
          className="mb-3"
          fill
          onSelect={(e) => handleTab(e)}
        >
          <Tab eventKey="1" title="Glass Works">
            <ImageData />
          </Tab>
          <Tab eventKey="2" title="Granite Works">
            <ImageData />
          </Tab>
          <Tab eventKey="3" title="House Elevation">
            <ImageData />
          </Tab>
          <Tab eventKey="4" title="Modular Kitchen">
            <ImageData />
          </Tab>
          <Tab eventKey="5" title="Painting Works">
            <ImageData />
          </Tab>
          <Tab eventKey="6" title="Weathering Works">
            <ImageData />
          </Tab>
        </Tabs>
      </div>
    </>
  );
};

export default ProjectsPage;
