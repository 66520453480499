export const config = {
  appName: "Shakisa",
  displayPhone: "+91 99650 38718",
  callPhome: "tel:+91 99650 38718",
  timing: "Mon - Sat : 09.00 AM - 09.00 PM",
  address: "G.K Nagar Veppampattu, Thiruvalluvar",
  email: "contact@shakisaplanners.com",
};
export const menuItems = [
  { name: "Home", url: "/" },
  { name: "About", url: "/about" },
  { name: "Services", url: "/services" },
  { name: "Our Works", url: "/works" },
  { name: "Contact", url: "/contact" },
];
export const homeCarouselData = [
  {
    image:
      "https://as2.ftcdn.net/v2/jpg/01/94/62/25/1000_F_194622587_s87XmHBpwJdaU1sKTNQQkX9n2AJ1u6Zc.jpg",
    caption: "Caption 1",
    description: "Description Here",
  },
  {
    image:
      "https://as1.ftcdn.net/v2/jpg/01/69/15/06/1000_F_169150605_LdluFsZiOsEQxkBOHaUMjRgNLVcaHitT.jpg",
    caption: "Caption 2",
    description: "Description Here",
  },
  {
    image:
      "https://as2.ftcdn.net/v2/jpg/02/42/52/67/1000_F_242526706_Ft07FkNpWeFApuWdZhra3sF3eQWK13F6.jpg",
    caption: "Caption 3",
    description: "Description Here",
  },
];
export const chooseUsData = [
  {
    title: "Experence",
    description:
      "With years of industry expertise, we bring a wealth of knowledge to every project.",
  },
  {
    title: "Innovation",
    description:
      "We embrace cutting-edge technologies and modern trends to deliver solutions that stand the test of time.",
  },
  {
    title: "Client-Centric Approach",
    description:
      "Your satisfaction is our priority. We collaborate closely with clients to exceed expectations.",
  },
  {
    title: "Quality Assurance",
    description:
      "Rigorous quality control measures ensure that our projects meet and exceed industry standards.",
  },
];
export const servicesData = [
  {
    icon: "home",
    name: "Home Construction",
    data: "Independent House/Villa Constructions with High standards and Quality material.",
  },
  {
    icon: "house-damage",
    name: "Architecture Design",
    data: "Best in class Architecture includes Floor plans, 3D Elevations and Structural drawings.",
  },
  {
    icon: "spa",
    name: "Planning & Management",
    data: "A good construction plan is the basis for developing the budget and the schedule for work.",
  },
  {
    icon: "igloo",
    name: "Residential Architecture",
    data: "Personalized Designs for affordable Price.",
  },
  {
    icon: "tint",
    name: "Plumbing",
    data: "Best in class Plumbing and electrical designs",
  },
  {
    icon: "archway",
    name: "Landscaping Design",
    data: "Personalized and beautiful Landscape designs.",
  },
];
export const projectData = [
  {
    id: 1,
    name: "Glass",
    img: 8,
  },
  {
    id: 2,
    name: "Granite",
    img: 9,
  },
  {
    id: 3,
    name: "House",
    img: 4,
  },
  {
    id: 4,
    name: "Modular",
    img: 14,
  },
  {
    id: 5,
    name: "Painting",
    img: 9,
  },
  {
    id: 6,
    name: "Weatering",
    img: 5,
  },
];
