// Filename - ContactUs.jsx
import React from "react";
interface IArgs {
  pageName: string;
}
const PageHeader = (props: IArgs) => {
  return (
    <div
      className="container-fluid page-header py-5 mb-5 wow fadeIn"
      data-wow-delay="0.1s"
    >
      <div className="container py-5">
        <h1 className="display-3 mb-3 animated slideInDown text-primary">
          {props.pageName}
        </h1>
      </div>
    </div>
  );
};

export default PageHeader;
